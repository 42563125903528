body {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    line-height: 1em;
    min-height: 100%;
    background-color: var(--light-gray);
}
html {
    overflow-y: scroll;
}
:root {
    --page-max-width: 1300px;
    /* Colors: */
    --grey: #767676;
    --light-gray: #f7f7f7;
    --almost-black: #18212e;
    --unnamed-color-ff00e2: #ff00e2;
    --black: #1b1c1d;
    --brown: #a5673f;
    --pink: #e03997;
    --violet: #6435c9;
    --blue: #2185d0;
    --teal: #00b5ad;
    --green: #21ba45;
    --olive: #b5cc18;
    --yellow: #fec953;
    --orange: #f2711c;
    --red: #db2828;
    --purple: #a333c8;
    --chip-active: #678fac;
    --chip-hover: #2286fc;
    --chip-press: #678fac;
    --chip-disabled: #b8c4cc;
    --success-message-text: #1e561f;
    --error-message-text: #973937;
    --success-message-background: #fcfff5;
    --success-message-border: #a8c599;
    --error-message-background: #fff6f6;
    --error-message-border: #ebcccc;
    --warning-message-background: #fffaf3;
    --warning-message-border: #ccbea0;
    --warning-message-text: #7a4d05;
    --information-message-background: #f8ffff;
    --information-message-border: #bedfe6;
    --information-message-text: #0e566c;
    --table-alternate-background: #fafafb;

    --color-active: var(--yellow);

    /* Buttons - Restore old Style */
    --border-radius-rounded: 0.25rem;
    --font-family-inter-medium: "Roboto", sans-serif;
    --font-size-md: 1rem;
    --font-weight-bold: 500;

    lib-button button.btn {
        background-color: var(--yellow);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 700;
        padding: 10px 24px;
        text-align: center;
        transition:
            background-color 0.25s,
            color 0.25s;
        &:hover {
            background-color: #ffd782;
        }
        &:disabled {
            background-color: grey;
            color: #a9a9a9;
            cursor: default;
        }
    }
}
